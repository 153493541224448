<template>
  <!--app首页内容管理-->
  <div class="columnHomepage">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="display: flex;justify-content: space-between;overflow: hidden;">
        <div  class="menu" >
          <a-menu
              style="width: 100%;"
              mode="inline"
              v-model="menu"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="index"
                @click="leftChange(index)"
            >
              {{item.title}}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="center">
          <a-table :columns="columns" style="margin-bottom: 10px;min-height: 542px" :data-source="typeInfo[this.typeIndex].appContents"  rowKey="id"   :pagination="false"
          >
        <span slot="title" slot-scope="text,item">
            <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{text.title}}</span>
        </span>
            <span slot="sort" slot-scope="text,item">
          <a style="margin-right: 10px" @click="move('up',item.id)">上移</a>
          <a @click="move('down',item.id)">下移</a>
        </span>
            <span slot="operate" slot-scope="text,item">
          <a style="margin-left: 10px" @click="removedBtn(item.id)"> 下架</a>
        </span>
          </a-table>
          <div   style="display: flex;justify-content: flex-end;right: 62px;bottom: 3.5rem;z-index: 3">
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          @change="pageChange" />
          </div>

        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {delAppContent, getAppContent, postAppContentMove} from "@/service/homePageFeatures_api";

export default {
  name: "columnHomepage",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '标题',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: '来源',
          dataIndex: 'contentType',
        },
        {
          title: '上架时间',
          dataIndex: 'createdTime',
        },
        {
          title: '排序',
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },],
      InfoTable:[],
      menu:[0],
      typeInfo:[],
      typeIndex:0,
    }},
  created() {
    this.$store.dispatch('setManageHeaderTitle',"App首页内容管理")
  },
  mounted() {
    this.getAPPInfo(1)
  },
  methods:{
    async getAPPInfo(pageNum){
      this.spinning=true
      const info = await getAppContent(pageNum)
      if (info.code===0){
        this.typeInfo=info.data
        this.pagination.total = info.data[this.typeIndex].count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning=false
    },
    leftChange(index){
      this.typeIndex = index
      this.getAPPInfo(1)
    },
    //下架
    async removedBtn(id){
      const info= await delAppContent(id)
      if (info.code === 0){
        await this.getAPPInfo(this.pagination.current)
        this.$message.success('设置成功')
      }
    },
    //分页跳转
    pageChange(){
      this.getAPPInfo(this.pagination.current)
    },
    //排序
    async move(type,id){
      const data = {
        code:this.typeInfo[this.typeIndex].code,
        moveUpDown : type
      }
      const info = await  postAppContentMove(id,data)
      if (info.code===0){
        this.$message.success('设置成功')
        await this.getAPPInfo(this.pagination.current)
      }else {
        this.$message.error("设置失败,请重试"+result.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none;
}
.columnHomepage{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu{
    background-color: white;
    width: 14%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .center{
    background-color: white;
    width: 85%;
    margin-bottom: -1000px;
    padding: 15px 15px 1000px;
    ::v-deep .ant-table-title{
      padding: 0;
    }
  }
}
</style>
